import React from "react";
import "./index.css";

const Contact = () => {
  return (
    <div id="contact" className="contact__container">
      <div className="contact__inner-container">
        <h2 className="header-h2">Contact us: </h2>
        <div className="contact__grid-container">
          <div className="contact-email grid-area-email">
            <div className="contact-title">E-mail</div>
            <a href="mailto:info@zmmztrans.mk" className="contact-text">
              info@zmmztrans.mk
            </a>
            <a href="mailto:zmmztrans@gmail.com" className="contact-text">
              zmmztrans@gmail.com
            </a>
          </div>
          <div className="contact-phone grid-area-phone">
            <div className="contact-title">Phone number</div>
            <a href="tel:+389 2 616 1016" className="contact-text">
              +389 2 616 1016
            </a>
          </div>
          <div className="contact-facebook grid-area-facebook">
            <div className="contact-title">Facebook</div>
            <a
              href="https://www.facebook.com/zmmztrans"
              target="_blank"
              rel="noreferrer"
              className="contact-text"
            >
              /zmmztrans
            </a>
          </div>
          <div className="contact-instagram grid-area-instagram">
            <div className="contact-title">Instagram</div>
            <a
              href="https://www.instagram.com/zmmz_trans/"
              target="_blank"
              rel="noreferrer"
              className="contact-text"
            >
              @zmmz_trans
            </a>
          </div>
          <div className="contact-location grid-area-location">
            <div className="contact-title">Location</div>
            <a
              href="https://www.google.com/maps/place/ZMMZ-TRANS/@41.9918391,21.4716247,14.31z/data=!4m5!3m4!1s0x13543f675b31a911:0x50a1fd0d54a6177e!8m2!3d41.9936554!4d21.4873937"
              target="_blank"
              rel="noreferrer"
              className="contact-text"
            >
              Location : ul. Pero Nakov br. 134 Skopje, North Macedonia
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;

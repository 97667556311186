export const modalData = () => {
  const modalData = [
    {
      id: "B2C",
      title: "B2C",
      text: "B2C (business-to-consumer) sales refers to a sales model in which business target individual consumers.",
    },
    {
      id: "B2B",
      title: "B2B",
      text: "B2B (business-to-business) is a service that involves a single one off or multiple commercial transactions between two businesses, as opposed to a business delivering goods directly to the end user, i.e. the customer.",
    },
    {
      id: "FTL",
      title: "FTL",
      text: " FTL stands for a “Full Truckload,” which means you hire the entire shipment for your goods — whether or not your goods occupy the entire space in a semi-truck carrier.",
    },
    {
      id: "LTL",
      title: "LTL",
      text: "LTL stands for  “Less than Truckload” is the transportation of products or goods that does not require a full truckload due to the smaller nature of the parcel, the shipments are usually arranged on pallets.",
    },
    {
      id: "Courier-shipment",
      title: "Courier shipment",
      text: "Courier shipment focuses on door-to-door and/or same-day delivery.",
    },
    {
      id: "Moving-services",
      title: "Moving services",
      text: "Moving services are all-inclusive services for relocations like packing, loading, moving, unloading, unpacking, arranging of items to be shifted.",
    },
    {
      id: "Drop-shipping",
      title: "Drop Shipping",
      text: "Provide goods by direct delivery from the manufacturer to the retailer or customer.",
    },
    {
      id: "ADR",
      title: "ADR",
      text: "Agreement of 30 September 1957 concerning the International Carriage of Dangerous Goods by Road - a treaty that governs transnational transport of hazardous materials.",
    },
    {
      id: "HUB",
      title: "Logistics HUB",
      text: "A large-scale structure within which different logistics service providers collaborate in order to offer value-added services by sharing assets.",
    },
  ];

  return modalData;
};

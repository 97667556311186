import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import logo from "../../assets/logo--image.png";

export default function Navigation() {
  const [showHamnurberMenu, setShowHamburgerMenu] = useState(false);
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        return setShowHamburgerMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <nav className="navigation__container">
      <div className="navigation__inner-container">
        {window.innerWidth >= 770 ? (
          <a className="navigation__logo-container" href="#landing-page">
            <img className="navigation__logo" src={logo} alt="zmmz trans" />
            <p className="navigation__logo-text">ZMMZ TRANS</p>
          </a>
        ) : null}
        {window.innerWidth >= 770 ? (
          <ul className="navigation__list">
            <a href="#about" className="navigation__item">
              About
            </a>
            <a href="#offers" className="navigation__item">
              Offers
            </a>
            <a href="#contact" className="navigation__item">
              Contact
            </a>
          </ul>
        ) : (
          <div className="menuToggle" ref={ref}>
            <input
              type="checkbox"
              checked={showHamnurberMenu}
              onChange={() => setShowHamburgerMenu(!showHamnurberMenu)}
            />
            <span></span>
            <span></span>
            <span></span>
            <ul className="menu">
              <li>
                <a
                  href="#about"
                  onClick={() => setShowHamburgerMenu(!showHamnurberMenu)}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#offers"
                  onClick={() => setShowHamburgerMenu(!showHamnurberMenu)}
                >
                  Offers
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  onClick={() => setShowHamburgerMenu(!showHamnurberMenu)}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
        {window.innerWidth < 770 ? (
          <a className="navigation__logo-container" href="#landing-page">
            <img className="navigation__logo" src={logo} alt="zmmz trans" />
            <p className="navigation__logo-text">ZMMZ TRANS</p>
          </a>
        ) : null}
      </div>
    </nav>
  );
}

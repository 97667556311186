import React, { useState } from "react";
import "./index.css";
import Modal from "../Modal";
import warehouseAndTruck from "../../assets/about-picture-1.jpg";
import warehouse from "../../assets/about-picture-2.jpg";
import warehouseUnloadingTruck from "../../assets/about-picture-4.jpg";

export default function AboutUs() {
  const [showModal, setShowModal] = useState("");

  return (
    <>
      <div id="about"></div>
      <section className="about-us">
        {showModal && <Modal type={showModal} closeModal={setShowModal} />}
        <div className="about-us__container">
          <div className="about-us__content">
            <h2 className="header-h2">About Us ...</h2>
            <p className="about-us__text">
              <span className="spacer" />
              ZMMZ TRANS is a privately owned company with its main office in
              Skopje, North Macedonia.
            </p>
            <p className="about-us__text">
              <span className="spacer" />
              Since its foundation in 2006, we have been providing
              transportation and logistics services for domestic and foreign
              customers. The main focus is towards customers' trust and
              satisfaction.
            </p>
            <p className="about-us__text">
              <span className="spacer" />
              Today ZMMZ TRANS is in contract with a logistics and customs
              brokerage services{" "}
              <span
                className="explanation-link"
                onClick={() => setShowModal("HUB")}
              >
                <a href="#modal" className="modal-hyperlink">
                  HUB
                </a>
              </span>
              , ready to accommodate all kinds of general as well as{" "}
              <span
                className="explanation-link"
                onClick={() => setShowModal("ADR")}
              >
                <a href="#modal" className="modal-hyperlink">
                  ADR
                </a>
              </span>{" "}
              goods and cargo, within its modern storage facilities, on over
              2500m2 of land.
            </p>
          </div>

          <div className="about-us__images">
            <img
              className="about__image grid-area-one"
              src={warehouseAndTruck}
              alt="warehouseAndTruck"
            />
            <img
              className="about__image grid-area-two"
              src={warehouse}
              alt="warehouse"
            />
            {(window.innerWidth <= 700 || window.innerWidth >= 900) && (
              <img
                className="about__image grid-area-three"
                src={warehouseUnloadingTruck}
                alt="warehouseUnloadingTruck"
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

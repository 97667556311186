import React, { useRef, useEffect } from "react";
import "./index.css";
import { modalData } from "./modalData";

const Modal = ({ type, closeModal }) => {
  const data = modalData().find((item) => item.id === type);
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        return closeModal("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="modal" id="modal">
      <div
        className={`modal__container ${
          type === "ADR" || type === "HUB"
            ? "modal__container--right"
            : "modal__container--left"
        }`}
        ref={ref}
      >
        <div className="modal__navigation">
          <div className="modal__header">{data.title}</div>
          <div className="modal__close-button" onClick={() => closeModal("")}>
            X
          </div>
        </div>
        <div className="modal__body">
          <div className="modal__text">"{data.text}"</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React from "react";

import LandingPage from "./components/LandingPage";
import "./index.css";

function App() {
  return <LandingPage />;
}

export default App;

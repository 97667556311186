import React from "react";
import "./index.css";
import landingPageImage from "../../assets/about.jpg";
import Offers from "../Offers";
import AboutUs from "../AboutUs";
import Contact from "../Contact";
import Navigation from "../Navigation";

export default function LandingPage() {
  return (
    <main className="landingPage__container">
      <div className="landingPage__inner-container">
        <Navigation />
        <section id="landing-page" className="landingPage__hero-image">
          <img src={landingPageImage} alt="truck with logo" />
          <span className="slogan">
            ZMMZ TRANS <br></br> "For all your transport necessities"
          </span>
        </section>
        <AboutUs />
        <Offers />
        <Contact />
      </div>
    </main>
  );
}

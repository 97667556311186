import React, { useState } from "react";
import "../AboutUs/index.css";
import Modal from "../Modal";
import truck from "../../assets/offers-2.jpg";

export default function Offers() {
  const [showModal, setShowModal] = useState("");

  return (
    <>
      <div id="offers"></div>
      <section className="about-us">
        {showModal && <Modal type={showModal} closeModal={setShowModal} />}
        <div className="about-us__container">
          {window.innerWidth >= 770 && (
            <div className="offers-image__container">
              <img className="about__image" src={truck} alt="truck" />
            </div>
          )}
          <div className="about-us__content">
            <h2 className="header-h2">Offers ...</h2>
            <ul>
              <li className="about-us__text">
                ZMMZ TRANS has a fleet of diverse type of vehicles ready to
                support clients’ needs (120m3–24t; 100m3–24t ; 45m3–12t;
                40m3–7,5t; 18m3–3t; 12m3–1,2t; 2m3–0,7t).
              </li>
              <li className="about-us__text">
                ZMMZ TRANS is focused on providing{" "}
                <span
                  className="explanation-link"
                  onClick={() => setShowModal("FTL")}
                >
                  <a href="#modal" className="modal-hyperlink">
                    FTL
                  </a>
                </span>
                ,{" "}
                <span
                  className="explanation-link"
                  onClick={() => setShowModal("LTL")}
                >
                  <a href="#modal" className="modal-hyperlink">
                    LTL
                  </a>
                </span>{" "}
                and{" "}
                <span
                  className="explanation-link"
                  onClick={() => setShowModal("Courier-shipment")}
                >
                  <a href="#modal" className="modal-hyperlink">
                    Courier shipments
                  </a>
                </span>{" "}
                from EU via Bulgaria to Macedonia, Kosovo and Albania.
              </li>
              <li className="about-us__text">
                ZMMZ TRANS has established a network of routes on a weekly basis
                from Bulgaria to Macedonia, Kosovo, Albania and vice versa.
              </li>
              <li className="about-us__text">
                Online shipment tracking. Contract logistics, worldwide{" "}
                <span
                  className="explanation-link"
                  onClick={() => setShowModal("Drop-shipping")}
                >
                  <a href="#modal" className="modal-hyperlink">
                    Drop Shipping
                  </a>
                </span>{" "}
                via our drop point in Sofia, Bulgaria.
              </li>
              <li className="about-us__text">
                <span
                  className="explanation-link"
                  onClick={() => setShowModal("B2B")}
                >
                  <a href="#modal" className="modal-hyperlink">
                    B2B
                  </a>
                </span>{" "}
                ,
                <span
                  className="explanation-link"
                  onClick={() => setShowModal("B2C")}
                >
                  <a href="#modal" className="modal-hyperlink">
                    B2C
                  </a>
                </span>{" "}
                distribution.{" "}
              </li>
              <li className="about-us__text">
                <span
                  className="explanation-link"
                  onClick={() => {
                    setShowModal("Moving-services");
                  }}
                >
                  <a href="#modal" className="modal-hyperlink">
                    Moving services
                  </a>
                </span>
              </li>
            </ul>
          </div>
          {window.innerWidth < 770 && (
            <div className="short-about-us__container--image">
              <img className="about__image" src={truck} alt="truck" />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

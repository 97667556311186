import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import AboutUs from "./components/AboutUs";
import Offers from "./components/Offers";
import Contact from "./components/Contact";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<App />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);
